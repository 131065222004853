import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
		name: 'transitionpage',
		path: '/transitionpage',
		component: () => import('@index/views/transitionpage/index.vue'),
		hidden: true,

	},
	{
		name: 'joinmatch',
		path: '/joinmatch',
		component: () => import('@index/views/joinmatch/index.vue'),
		hidden: true,
		redirect: '/joinmatchinfo',
		children: [{
				name: 'joinmatchinfo',
				path: '/joinmatchinfo',
				component: () => import('@index/views/joinmatch/match/matchinfo/index.vue'),
			},
			{
				name: 'joinmatchformsignuptable',
				path: '/joinmatchformsignuptable',
				component: () => import('@index/views/joinmatch/match/formsignuptable/index.vue'),
			},
			{
				name: 'joinmatchgroupsetitem',
				path: '/joinmatchgroupsetitem',
				component: () => import('@index/views/joinmatch/match/groupsetitem/index.vue'),
			},
			{
				name: 'joinmatcharrangtable',
				path: '/joinmatcharrangtable',
				component: () => import('@index/views/joinmatch/match/matcharrangtable/index.vue'),
			},
			{
				name: 'joinmatchsummarytable',
				path: '/joinmatchsummarytable',
				component: () => import('@index/views/joinmatch/match/matchsummarytable/index.vue'),
			},
			{
				name: 'joinmatchmymatchtabe',
				path: '/joinmatchmymatchtabe',
				component: () => import('@index/views/joinmatch/match/mymatchtabe/index.vue'),
			},
			{
				name: 'joinmatchonlinesignup',
				path: '/joinmatchonlinesignup',
				component: () => import('@index/views/joinmatch/match/onlinesignup/index.vue'),
			},
			{
				name: 'joinmatchrealnameauth',
				path: '/joinmatchrealnameauth',
				component: () => import('@index/views/joinmatch/match/realnameauth/index.vue'),
			},
			{
				name: 'joinmatchscoretable',
				path: '/joinmatchscoretable',
				component: () => import('@index/views/joinmatch/match/scoretable/index.vue'),
			},
		]
	},
	{
		name: 'team',
		path: '/team',
		component: () => import('@index/views/team/index.vue'),
		hidden: true,
		redirect: '/teammatchinfo',
		children: [{
				name: 'teammatchinfo',
				path: '/teammatchinfo',
				component: () => import('@index/views/team/matchinfo/index.vue'),
			},
			{
				name: 'teamplayerinfo',
				path: '/teamplayerinfo',
				component: () => import('@index/views/team/playerinfo/index.vue'),
			},
			{
				name: 'teamteaminfo',
				path: '/teamteaminfo',
				component: () => import('@index/views/team/teaminfo/index.vue'),
			},
		]
	},

	{
		path: '/mymatchs',
		component: () => import('@index/views/mymatchs/index.vue'),
		hidden: true,
		redirect: '/matchList',
		children: [{
				name: 'hosterMatchInfo',
				path: '/hosterMatchInfo',
				component: () => import('@index/views/mymatchs/match/matchinfo/index.vue'),
			}, {
				name: 'matchList',
				path: '/matchList',
				component: () => import('@index/views/mymatchs/matchs/index.vue'),
			},
			{
				name: 'matchinfo',
				path: '/matchinfo',
				component: () => import('@index/views/mymatchs/matchs/matchinfo.vue'),
				meta: {
					keepAlive: false
				}
			},
			{
				name: 'certconfig',
				path: '/certconfig',
				component: () => import('@index/views/mymatchs/matchs/certconfig/index.vue'),
				meta: {
					keepAlive: false
				}
			},
			{
				name: 'onlinesignuptable',
				path: '/onlinesignuptable',
				component: () => import('@index/views/mymatchs/match/onlinesignuptable/index.vue'),
			},
			{
				name: 'formsignuptable',
				path: '/formsignuptable',
				component: () => import('@index/views/mymatchs/match/formsignuptable/index.vue'),
			},
			{
				name: 'matcharrangtable',
				path: '/matcharrangtable',
				component: () => import('@index/views/mymatchs/match/matcharrangtable/index.vue'),
			},
			{
				name: 'matchsummarytable',
				path: '/matchsummarytable',
				component: () => import('@index/views/mymatchs/match/matchsummarytable/index.vue'),
			},
			{
				name: 'matchdetailstable',
				path: '/matchdetailstable',
				component: () => import('@index/views/mymatchs/match/matchdetailstable/index.vue'),
			},
			{
				name: 'scoretable',
				path: '/scoretable',
				// component: () => import('@index/views/mymatchs/match/scoretable/index.vue'),
				component: () => import('@index/views/mymatchs/match/transcript/transcript.vue'),
			},
			{
				name: 'groupsetitem',
				path: '/groupsetitem',
				component: () => import('@index/views/mymatchs/match/groupsetitem/index.vue'),
			},
			{
				name: 'teammanage',
				path: '/teammanage',
				component: () => import('@index/views/mymatchs/match/teammanage/index.vue'),
			},

		]
	},
	{
		path: '/topublicmatch',
		name: 'topublicmatch',
		component: () => import('@index/views/mymatchs/matchs/publicmatch.vue'),

	},
	{
		path: '/',
		component: () => import('@index/views/indextransition/index.vue'),
		hidden: true,
	},

	{
		path: '/indextransition',
		component: () => import('@index/views/indextransition/index.vue'),
		hidden: true,
	},
	{
		path: '/home',
		name: 'home',
		component: () => import('@index/views/home/index.vue'),
		hidden: true,
	},
	{
		path: '/agentlogin',
		name: 'agentlogin',
		component: () => import('@index/views/agentlogin/index.vue'),
		hidden: true,
	},
	{
		path: '/mobile/login',
		name: 'mobileLogin',
		component: () => import('@index/views/mobileLogin/mobileLogin.vue'),
		hidden: true,
	},
	{
		path: '/print',
		name: 'print',
		component: () => import('@index/views/mymatchs/match/transcript/print.vue'),
		hidden: true,
	}
	//   {
	//     path: '/404',
	//     component: () => import('@index/views/error-page/404'),
	//     hidden: true
	//   },
	//   {
	//     path: '/401',
	//     component: () => import('@index/views/error-page/401'),
	//     hidden: true
	//   }
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

// 路由导航
router.beforeEach((to, from, next) => {
	const token = sessionStorage.getItem('token');
	if (location.host == process.env.VUE_APP_ADMIN_HOST && !token) {
		location.href = "/home"
		return;
	}

	if (/iPhone|Mobile|Android/.test(window.navigator.userAgent)) {
		//document.title = "我是记分长";
		if (to.name != 'mobileLogin') {
			next({
				name: 'mobileLogin'
			});
		} else {
			next();
		}
		return;
	}
	document.title = '报名系统';
	if (to.name == "matchinfo" && from.name == 'matchList') {
		location.href = to.fullPath
	}
	next();

})

export default router
import axios from 'axios'
import router from '@index/router'
import {
	Message,
	Loading
} from 'element-ui'

// create an axios instance
const service = axios.create({
	baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
	// withCredentials: true, // send cookies when cross-domain requests
	timeout: 60000 // request timeout
})
let loading = null;

function startLoading() { //使用Element loading-start 方法
	loading = Loading.service({
		lock: true,
		text: '加载中……',
		background: 'rgba(0, 0, 0, 0.7)'
	})
}

function endLoading() { //使用Element loading-close 方法
	loading.close()
}

let needLoadingRequestCount = 0
export function showFullScreenLoading() {
	if (needLoadingRequestCount === 0) {
		startLoading()
	}
	needLoadingRequestCount++
}

export function tryHideFullScreenLoading() {
	if (needLoadingRequestCount <= 0) return
	needLoadingRequestCount--
	if (needLoadingRequestCount === 0) {
		endLoading()
	}
}

// request interceptor
service.interceptors.request.use(
	config => {
		// do something before request is sent


		// let each request carry token
		// ['X-Token'] is a custom headers key
		// please modify it according to the actual situation
		let token = sessionStorage.getItem('token');
		if (token) {
			config.headers['Authorization'] = token;
		} else {
			config.headers['X-Token'] = '';
		}
		showFullScreenLoading();
		return config
	},
	error => {
		// do something with request error
		console.log(error) // for debug
		return Promise.reject(error)
	}
)

// response interceptor
service.interceptors.response.use(
	/**
	 * If you want to get http information such as headers or status
	 * Please return  response => response
	 */

	/**
	 * Determine the request status by custom code
	 * Here is just an example
	 * You can also judge the status by HTTP Status Code
	 */
	response => {
		
		const res = response.data
		tryHideFullScreenLoading();
		// if the custom code is not 20000, it is judged as an error.   
	
		if (res.status !== 200) {
			// Message({
			//   message: res.msg || 'Error',
			//   type: 'error',
			//   duration: 5 * 1000
			// })

			// 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
			if (res.status === 50008 || res.status === 50012 || res.status === 50014) {
				// to re-login
				// MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again',
				//   'Confirm logout', {
				//     confirmButtonText: 'Re-Login',
				//     cancelButtonText: 'Cancel',
				//     type: 'warning'
				//   }).then(() => {
				//   store.dispatch('user/resetToken').then(() => {
				//     location.reload()
				//   })
				//  })
			}
			// return Promise.reject(new Error(res.msg || 'Error'))
			return res;
		} else {
			return res
		}
	},
	error => {
		tryHideFullScreenLoading();
		if (error && error.response && error.response.status && error.response.status == 401) {
			var myHomePage = sessionStorage.getItem('myHomePage')
			Message({
				message: '登陆信息失效，即将退出!',
				type: 'error',
				duration: 5 * 1000
			})
			sessionStorage.clear();
			router.push(myHomePage ? myHomePage : '/home') // 注意，这里使用了Vue的全局实例
		} else if (error.message && error.message.indexOf('Network Error') >= 0) {
			Message({
				message: '请检测网络是否正常!',
				type: 'error',
				duration: 5 * 1000
			});
			return;
		} else {
			Message({
				message: '服务器正在升级更新，可能需要耽误几分钟，请稍后再使用...',
				type: 'error',
				duration: 5 * 1000
			});
			return;
		}
		return Promise.reject(error)
	}
)

export default service